import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../../services/api.service";
import {errorLogger} from "../../../../services/error-logger";
import {API_COMPETITIONS, API_COMPETITIONS_INIT, API_USERS_INIT} from "../../../../constants";
import {toastr} from "react-redux-toastr";
import {DartsCompetition, DartsCompetitions} from "./types";
import {IGetAllUserClients} from "../../usersNRoles/cmsUsersNRolesSlice";
import {ISelectOption} from "../tournaments/types";

export interface IGetCompetitionsResponse {
    totalItems: 0,
    totalPages: 0,
    pageNum: 0,
    pageSize: 0,
    items: DartsCompetition[]
}

export interface ICompetitionInitResponse {
    clients: ISelectOption[],
    types: ISelectOption[],

}

const initialState: DartsCompetitions = {
    dartsCompetitions: [] as DartsCompetition[],
    competition: {
        name: '',
        clientId: 0,
        clientName: "",
        id: 0,
        isActive: false,
        type: 0,
        typeName: ""
    },
    isLoading: true,
    isVisibleForm: false,
    editMode: false,
    editCompetitionId: null,
    noContent: null,
    errorMessage: '',
    totalItems: 0,
    totalPages: 0,
    pageNum: 1,
    pageSize: 10,
    clients: [],
    competitionTypeOptions: []

}

export const getDartsCompetitions = createAsyncThunk<IGetCompetitionsResponse, string>(
    'dartsCompetitions/get',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_COMPETITIONS}?${query}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts competitions.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Competitions: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getCompetitionInitOptions = createAsyncThunk<ICompetitionInitResponse>(
    'competition/get-all-clients',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_COMPETITIONS_INIT}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch all client.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get users: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data)

        }
    }
)

export const getDartsSingleCompetition = createAsyncThunk<DartsCompetition, number>(
    'dartsCompetitions/getSingle',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_COMPETITIONS + `/${id}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts single competition.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Competitions: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data)
        }
    }
)


export const createDartsCompetition = createAsyncThunk<DartsCompetition, DartsCompetition>(
    'dartsCompetitions/post',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_COMPETITIONS, payload);
            toastr.success("CMS", "Darts competition has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a competition.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create darts competition: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)


export const editDartsCompetition = createAsyncThunk<DartsCompetition, DartsCompetition>(
    'dartsCompetitions/edit',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_COMPETITIONS}`, payload);
            toastr.success("CMS", "Darts competition has been edited");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a competition.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit darts competition: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)

export const deleteDartsCompetition = createAsyncThunk<DartsCompetition, number>(
    'dartsCompetitions/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_COMPETITIONS}/${id}`);
            toastr.success('', 'Darts competition has been deleted');
            return data;
        } catch (error) {
            toastr.error('CMS', error.response.data);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete darts competition: ${error.name}: ${error.message}`,
                errorDate: new Date(),
            };
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);


export const cmsCompetitionSlice = createSlice({
    name: 'cmsDartsCompetitions',
    reducers: {
        changePageSize: (state, action) => {
            state.pageSize = action.payload
        },
        showCompetitionForm: (state) => {
            state.isVisibleForm = true;
        },
        hideCompetitionForm: (state,) => {
            state.isVisibleForm = false;
            state.editMode = false;
        },

        setPageNumber: (state, action) => {
            state.pageNum = action.payload
        },
        setEditModeCompetitionForm: (state, action: PayloadAction<number | null>) => {
            state.editMode = true;
            state.isVisibleForm = true;
            state.editCompetitionId = action.payload;
        },
        clearSingleCompetition: (state) => {
            state.competition = {
                name: '',
                clientId: 0,
                isActive: false,
                type: 0,
                clientName: "",
                id: 0,
                typeName: ""
            };
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsCompetitions.fulfilled, (state, action) => {
                state.dartsCompetitions = action.payload.items;
                state.noContent = !action.payload.items.length;
                state.isLoading = false;
                state.totalPages = action.payload.totalPages
            })
            .addCase(getCompetitionInitOptions.fulfilled, (state, action) => {
                state.clients = action.payload.clients
                state.competitionTypeOptions = action.payload.types
            })
            .addCase(getDartsCompetitions.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(getDartsSingleCompetition.fulfilled, (state, action) => {
                state.competition = action.payload;
            })
            .addCase(deleteDartsCompetition.pending, (state) => {
                state.isLoading = true;

            })
            .addCase(deleteDartsCompetition.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.dartsCompetitions = state.dartsCompetitions.filter(
                        (competition) => competition.id !== action.meta.arg
                    );
                }
                state.isLoading = false;

            })
            .addCase(deleteDartsCompetition.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createDartsCompetition.pending, (state) => {
                state.isVisibleForm = false;
                state.isLoading = true;
            })
            .addCase(createDartsCompetition.fulfilled, (state, action) => {
                state.dartsCompetitions = state.dartsCompetitions.concat(action.payload);
                state.isLoading = false;
            })
            .addCase(createDartsCompetition.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(editDartsCompetition.pending, (state) => {
                state.isVisibleForm = false;
                state.isLoading = true;
                state.editMode = false;
            })
            .addCase(editDartsCompetition.fulfilled, (state, action) => {
                state.dartsCompetitions = state.dartsCompetitions.map((competition) => {
                    if (competition.id === action.payload.id) {
                        competition = action.payload
                    }
                    return competition
                })
                state.isLoading = false;
            })
            .addCase(editDartsCompetition.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const {
    showCompetitionForm,
    hideCompetitionForm,
    setPageNumber,
    setEditModeCompetitionForm,
    clearSingleCompetition,
    changePageSize
} = cmsCompetitionSlice.actions
export default cmsCompetitionSlice.reducer;

