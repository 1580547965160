import React, {useEffect, useMemo, useState} from "react";
import {Table, Button, Icon, Pagination, Input, Checkbox, Select} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import DartsCompetitionsForm from "./DartsCompetitionsForm/DartsCompetitionsForm";
import CheckboxCms from "../../../../components/CheckboxCms/CheckboxCms";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    changePageSize,
    clearSingleCompetition,
    deleteDartsCompetition, getCompetitionInitOptions,
    getDartsCompetitions, getDartsSingleCompetition,
    setEditModeCompetitionForm, setPageNumber,
    showCompetitionForm
} from "../../../../store/cms/darts/competitions/competitionsSlice";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";
import styles from "./style.module.scss"
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";

const DartsCompetitions = () => {
    const dispatch = useAppDispatch();
    const {
        dartsCompetitions,
        isVisibleForm,
        isLoading,
        noContent,
        pageNum,
        pageSize,
        totalPages,
        clients
    } = useAppSelector((state) => state.cmsCompetitions);
    const {user} = useAppSelector((state) => state.auth);
    const [searchWord, setSearchWord] = useState("");
    const [client, setClient] = useState<any>(null);
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        dispatch(getDartsCompetitions(buildQueryString({pageNum, pageSize,isActive})))
        dispatch(getCompetitionInitOptions())
    }, []);

    const clientOptions = useMemo(() => {
        const allClients = clients.map(client => {
            return {key: client.id, value: client.id, text: client.name}
        })
        allClients.unshift({key: 0, value: 0, text: "All"})
        return allClients
    }, [clients]);

    const openCompetitionForm = () => {
        dispatch(showCompetitionForm());
    }

    const updateCompetitionBtnHandler = (id: number) => {
        dispatch(getDartsSingleCompetition(id));
        dispatch(setEditModeCompetitionForm(id));
        dispatch(clearSingleCompetition());
    }

    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setPageNumber(activePage))
        const params = {pageNum: activePage, pageSize,search:searchWord}
        dispatch(getDartsCompetitions(buildQueryString(params)));
    }

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {search: e.target.value, pageNum: 1, pageSize,isActive}
        setSearchWord(e.target.value)
        dispatch(getDartsCompetitions(buildQueryString(params)));
    }

    const onChangeClient = (clientId: any) => {
        const params = {search: searchWord, pageNum: 1, pageSize, clientId,isActive}
        setClient(clientId)
        dispatch(getDartsCompetitions(buildQueryString(params)))
    }

    const onClickIsActive = () => {
        setIsActive(!isActive)
        const params = {search: searchWord, pageNum, pageSize, clientId:client,isActive:!isActive}
        dispatch(getDartsCompetitions(buildQueryString(params)))
    }

    const pagerSelectOptions = [
        {key:10,value:10,text:"10 Items"},
        {key:20,value:20,text:"20 Items"},
        {key:50,value:50,text:"50 Items"},
    ]

    const onChangePageSize = (value:any) => {
        const params = {search: searchWord, pageNum, pageSize:value, clientId:client,isActive}
        dispatch(getDartsCompetitions(buildQueryString(params)))
        dispatch(changePageSize(value))
    }

    return (
        <div>
            <div className={styles.topControls}>
                <div className={styles.filter}>
                    <div>
                        <Input
                            icon='search'
                            placeholder='Search...'
                            value={searchWord}
                            onChange={(e) => onSearch(e)}
                        />
                    </div>
                    <div>
                        <Checkbox
                            checked={isActive}
                            onClick={onClickIsActive}
                            label="Is Active"
                        />
                    </div>
                    <div>
                        <Select
                            value={client}
                            onChange={(e, {value}) => onChangeClient(value)}
                            defaultValue={0}
                            placeholder='Select client'
                            options={clientOptions}
                        />
                    </div>
                </div>
                <Button onClick={openCompetitionForm} id="add_competition" color="green">
                    Add
                </Button>
            </div>
            {noContent ? (
                <div className="schedule-info-block">No competitions</div>
            ) : (
                <Table celled>
                    {isLoading && <DimmerLoader/>}
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user) &&
                                <Table.HeaderCell textAlign="center">
                                    Id
                                </Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">
                                Competition name
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Competition type
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                A
                            </Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dartsCompetitions?.map((competition) => {
                            return (
                                <Table.Row key={competition.id}>
                                    {checkIsUserAdmin(user) &&
                                        <Table.Cell textAlign="center">
                                            {competition.id}
                                        </Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">
                                        {competition.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {competition.typeName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <CheckboxCms value={competition.isActive} isDisabled={true} isReadyOnly={true}/>
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <div className={styles.tableControls}>
                                            <DartsDeleteModal
                                                id={competition.id!}
                                                deleteAction={(id: number) => dispatch(deleteDartsCompetition(id))}
                                                confirmText={"Are you sure you want to delete your competition?"}
                                                titleModal={"Delete your Competition"}
                                            />
                                            <Icon
                                                name='pencil alternate'
                                                link
                                                color="grey"
                                                size="small"
                                                onClick={() => updateCompetitionBtnHandler(competition?.id!)}
                                            >
                                            </Icon>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {isVisibleForm && <DartsCompetitionsForm/>}
            {!noContent && (
                <div>
                    <div className={styles.pagerInner}>
                        <div>
                            <Select
                                onChange={(e,{value})=>onChangePageSize(value)}
                                value={pageSize}
                                defaultValue={pageSize}
                                className={styles.pagerSelect}
                                options={pagerSelectOptions}
                            />
                        </div>
                        <Pagination
                            floated="right"
                            size="mini"
                            activePage={pageNum}
                            totalPages={totalPages}
                            onPageChange={(e, {activePage}) => onChangePager(activePage)}
                        />
                    </div>
                </div>

            )}
        </div>
    );
};

export default DartsCompetitions;
